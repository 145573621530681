// extracted by mini-css-extract-plugin
export var button = "header-module--button--wIoNk";
export var buttonDiv = "header-module--buttonDiv--GTwRO";
export var buttonDivFixed = "header-module--buttonDivFixed--wgauc";
export var header = "header-module--header--Zms1V";
export var homelink = "header-module--homelink--KDa2C";
export var name = "header-module--name--uy5-G";
export var navbar = "header-module--navbar--kwNbS";
export var setBlack = "header-module--setBlack--YOeud";
export var setWhite = "header-module--setWhite--bWWyN";
export var svg = "header-module--svg--zEmX8";