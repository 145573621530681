import React from "react"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import * as menuFooterStyles from "./menuFooter.module.css"

const Menufooter = () => {
  return (
    <footer className={menuFooterStyles.footer}>
      <OutboundLink
        href="https://www.linkedin.com/in/jentestevens"
        target="_blank"
        rel="noreferrer"
        aria-label="Linkedin"
        className={menuFooterStyles.linkedinLink}
      >
        <svg
          className={menuFooterStyles.linkedin}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </OutboundLink>
      <OutboundLink
        href="mailto:jente-stevens@hotmail.com"
        aria-label="Mail"
        className={menuFooterStyles.mailLink}
      >
        <svg
          className={menuFooterStyles.mail}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 18"
        >
          <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85 0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
        </svg>
      </OutboundLink>
    </footer>
  )
}

export default Menufooter
