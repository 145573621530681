import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { motion } from "framer-motion"

// import { use100vh } from "react-div-100vh"

import * as headerStyles from "./header.module.css"

const Header = ({ toggleMenu, isOpen }) => {
  const variant = isOpen ? "opened" : "closed"
  const top = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: 45,
      y: 4.5,
    },
  }
  const bottom = {
    closed: {
      rotate: 0,
      y: 0,
    },
    opened: {
      rotate: -45,
      y: -4.5,
    },
  }

  // const height = use100vh()
  // const tenthHeight = height ? height / 10 : "10vh"

  return (
    <header className={headerStyles.header}>
      <nav
        className={headerStyles.navbar}
        // style={{ height: tenthHeight }}
      >
        <Link to="/" className={headerStyles.homelink}>
          <div className={headerStyles.name}>Jente Stevens</div>
        </Link>
        <div
          className={
            isOpen
              ? `${headerStyles.buttonDiv} ${headerStyles.buttonDivFixed}`
              : `${headerStyles.buttonDiv}`
          }
        >
          <button
            className={headerStyles.button}
            onClick={toggleMenu}
            aria-label="menu"
          >
            <motion.svg
              className={headerStyles.svg}
              width="25"
              height="25"
              viewBox="0 0 25 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              animate={variant}
            >
              <motion.rect
                className={
                  isOpen ? headerStyles.setWhite : headerStyles.setBlack
                }
                width="25"
                height="3"
                rx="1.5"
                fill="black"
                variants={top}
              />
              <motion.rect
                className={
                  isOpen ? headerStyles.setWhite : headerStyles.setBlack
                }
                y="9"
                width="25"
                height="3"
                rx="1.5"
                fill="black"
                variants={bottom}
              />
            </motion.svg>
          </button>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
