import React from "react"
import { Link } from "gatsby"
import * as menuHeaderStyles from "./menuHeader.module.css"

const MenuHeader = ({ toggleMenu }) => {
  return (
    <header className={menuHeaderStyles.header}>
      <nav className={menuHeaderStyles.navbar}>
        <Link to="/" className={menuHeaderStyles.homelink} onClick={toggleMenu}>
          <div className={menuHeaderStyles.name}>Jente Stevens</div>
        </Link>
      </nav>
    </header>
  )
}

export default MenuHeader
