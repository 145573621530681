import React from "react"
import MenuHeader from "./menuHeader"
import Menufooter from "./menuFooter"
import { Link } from "gatsby"

import * as menuStyles from "./menu.module.css"

const Menu = ({ isOpen, toggleMenu }) => {
  return (
    <div
      className={
        isOpen
          ? `${menuStyles.container} ${menuStyles.showMenu}`
          : `${menuStyles.container}`
      }
    >
      <MenuHeader toggleMenu={toggleMenu} />
      <nav>
        <ul className={menuStyles.list}>
          <li className={menuStyles.li}>
            <Link to="/work" className={menuStyles.link} onClick={toggleMenu}>
              <span className={menuStyles.index}>01</span>
              <span className={menuStyles.menuItem}>Work.</span>
            </Link>
          </li>
          <li className={menuStyles.li}>
            <Link to="/about" className={menuStyles.link} onClick={toggleMenu}>
              <span className={menuStyles.index}>02</span>
              <span className={menuStyles.menuItem}>About me.</span>
            </Link>
          </li>
          <li className={menuStyles.li}>
            <Link
              to="/contact"
              className={menuStyles.link}
              onClick={toggleMenu}
            >
              <span className={menuStyles.index}>03</span>
              <span className={menuStyles.menuItem}>Say hello.</span>
            </Link>
          </li>
        </ul>
      </nav>
      <Menufooter />
    </div>
  )
}

export default Menu
